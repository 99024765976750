.cs-button {
	height: 40px;
	text-align: center;
	border-radius: 3px;
	min-width: 137px;
	border: 1px solid transparent;
	background: linear-gradient(180deg, #fb0f3b 0%, #e30a33 97.83%);
	padding: 0.25rem 1rem;
	position: relative;
	cursor: pointer;
	font-size: 1rem;
	color: white;
	// box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
	font-size: 0.875rem;
	font-weight: $boldFontWeight;
	&.link.__tag {
		border-radius: 12px;
		height: auto;
		padding: 0.25rem 0.75rem;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		font-size: 0.8em;
		> * {
			height: 14px;
		}
		.__tag-label {
			// padding: 0 .75em;
		}
		.material-icons {
			font-size: 14px;
			&.__tag-icon-left {
				margin-right: 0.5em;
			}
			&.__tag-icon-right {
				margin-left: 0.5em;
			}
		}
		&.__tag_white {
			.material-icons {
				color: #92999f;
			}
			border: 1px solid #c7cdcf;
			background: linear-gradient(0deg, #f7f7f7 0%, #ffffff 100%);
			&:hover {
				background: linear-gradient(0deg, #e5e5e5 0%, #f7f7f7 100%);
			}
			&:focus {
				background: linear-gradient(0deg, #d9d9d9 0%, #f3f3f3 100%);
			}
		}
		&.__tag_red {
			.material-icons {
				color: white;
			}
			background: linear-gradient(180deg, #fb0f3b 0%, #e90f38 100%);
			color: white;
		}
		&:hover {
			background: linear-gradient(180deg, #ff4063 0%, #e42f50 100%);
		}
		&:focus {
			background: linear-gradient(180deg, #e91c42 0%, #d02242 100%);
		}
	}
	&.tooltip {
		&:hover {
			&:before {
				position: absolute;
				top: 100%;
				height: 1rem;
				left: 50%;
				width: 1rem;
				background: black;
				content: '';
				transform: translateX(-50%) rotate(45deg);
			}
			&:after {
				white-space: nowrap;
				content: attr(data-tooltip);
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				background: black;
				border-radius: 3px;
				color: white;
				font-weight: bold;
				padding: 0.66rem 0.75rem;
				font-family: 'Roboto';
				font-size: 0.75rem;
				margin-top: 0.25rem;
				z-index: 100;
			}
		}
	}
	&.small {
		height: 34px;
		min-width: 0px;
		padding: 0.5rem 1rem;
		font-size: 0.75rem;
	}
	&:hover {
		background: linear-gradient(180deg, #ff4063 0%, #e42f50 100%);
	}
	&:focus {
		outline: none;
		background: linear-gradient(180deg, #e91c42 0%, #d02242 100%);
	}
	&.pending {
		font-size: 0px;
		&::after {
			content: '';
			display: inline-block;
			font-size: 14px;
			height: 20px;
			width: 20px;
			border: 2px solid transparent;
			border-right-color: white;
			border-top-color: white;
			border-radius: 50%;
			animation: spin 0.8s linear infinite;
		}
	}
	&:disabled, &.disabled {
		opacity: 0.4;
		cursor: not-allowed;
		pointer-events: auto;
	}
	&.white {
		background: linear-gradient(0deg, #f7f7f7 0%, #ffffff 100%);
		color: $baseFontColor;
		border: 1px solid #c7cdcf;
		// box-shadow: 0 2px 6px 0 rgba(197,197,197,0.47);
		&.pending {
			&::after {
				border-right-color: $baseFontColor;
				border-top-color: $baseFontColor;
			}
		}
		&:hover {
			background: linear-gradient(0deg, #e5e5e5 0%, #f7f7f7 100%);
			// box-shadow: 0 2px 6px 0 rgba(125,124,126,0.47);
		}
		&:focus {
			background: linear-gradient(0deg, #d9d9d9 0%, #f3f3f3 100%);
			// box-shadow: 0 2px 6px 0 rgba(169,169,169,0.47);
		}
	}
	&.outline {
		background: linear-gradient(180deg, #ffffff 0%, #fef2f5 100%);
		color: $red;
		// box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
		border-color: $red;
		&.pending {
			&::after {
				border-right-color: $red;
				border-top-color: $red;
			}
		}
		&:hover {
			background: linear-gradient(180deg, #fff8f9 0%, #ffdae1 100%);
		}
		&:focus {
			background: linear-gradient(180deg, #fdebef 0%, #ffc1cc 100%);
			// box-shadow: 0 3px 10px 0 #FF0030, 0 3px 14px 0 rgba(84,92,99,0.58);
		}
	}
	&.link {
		background: none;
		min-width: 0px;
		box-shadow: none;
		border: none;
		padding: 0;
		color: $baseFontColor;
	}
}

@keyframes :global(spin) {
	0% {
		transform: rotate(0deg);
	}
	30% {
		transform: rotate(200deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
